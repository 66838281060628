.cat_add {
  margin-left: 0px;
  margin-right: 0px;
}

.error-text {
  /* position: absolute;
  display: contents;
  border-width: 1px;
  padding: 2px 5px 1px 6px; */
}

.th-origin th {
  z-index: 1;
}

.states-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  padding-inline-start: 0px !important;
}

.origin-desc {
  line-height: 40px;
  width: 600px;
  overflow: hidden;
  display: block;
  word-break: break-word;
  white-space: pre-wrap;
}

.origin-desc-add {
  width: 600px;
  overflow: hidden;
  display: block;
  word-break: break-all;
  white-space: pre-wrap;
}

.origin-country-add {
  width: 200px;
  word-break: break-all;
  white-space: pre-wrap;
}

/* .css-2b097c-container {
  min-width: 175px;
} */

.add-btn {
  margin-right: 12px;
  border-radius: 5px;
  border: solid 1px #ffffff;
  background-color: #0f51a1;
  padding: 7px 28px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 12px;
  color: #ffffff;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.head_name {
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #8a94a6;
}

.head_description {
  font-family: "CircularStd-Medium, sans-serif" !important;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.71;
  letter-spacing: normal;
  color: #0a1f44;
}
#origin-select {
  position: absolute !important;
  width: 18%;
  top: 125px;
}

.css-2613qy-menu::before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  bottom: 100%;
  right: 13px;
  border-width: 0 5px 5px 5px;
  border-style: solid;
  border-color: rgba(255, 255, 255) transparent;
}
#state-select {
  position: absolute !important;
  width: 40%;
  top: 125px;
}
.css-1rhbuit-multiValue {
  color: #8a94a6 !important;
  background-color: #f6f7fb !important;
  border-radius: 3px !important;
  line-height: 20px;
}

.css-12jo7m5 {
  color: #8a94a6 !important;
}

.action-col-lg {
  flex: 0 0 70%;
  max-width: 70%;
}

.head-cat-col-lg {
  flex: 0 0 30%;
  max-width: 30%;
}

.btn-org {
  margin-top: -18.5px;
}

.num_head {
  flex: 0 0 50%;
  max-width: 50%;
}

textarea.wt-resize {
  resize: none;
}

.cat_head .form-group {
  margin-bottom: 0rem;
}

.can-btn-table {
  border-radius: 3px;
  background-color: #fff;
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8a94a6;
  margin-right: 6px;
  border: solid 1px #e1e4e8;
  padding: 6.5px 20px;
}

.save-btn-table {
  border-radius: 3px;
  background-color: #0f51a1;
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border: solid 1px #0f51a1;

  margin-right: 6px;
  padding: 6px 29px;
}

.act-btn {
  margin-top: 1rem;
  border-radius: 3px;
  background-color: #fff;
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8a94a6;
  border: solid 1px #e1e4e8;
}

.act-btn img {
  margin-right: 4px;
}

.date_head {
  flex: 0 0 50%;
  max-width: 50%;
}

form.cat_add .form-control-user {
  font-size: 14px;
  font-family: "CircularStd-Book, sans-serif" !important;
  border-radius: 4px;
  padding: 1.2rem 1rem;
  color: #8a94a6;
}

form.cat_add .form-control-user[readonly] {
  background: #fff;
  color: #8a94a6;
}

form.cat_add .form-control-label {
  font-size: 12px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #8a94a6;
}

.form-row-table {
  background: #f6f7fb;
}

.form-row-table td {
  line-height: 0;
}

.form-name {
  display: block;
  width: 70%;
  height: 30px;
  padding: 0.375rem 0.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-radius: 4px;
}

.form-link {
  display: block;
  width: 100%;
  height: 30px;
  padding: 0.375rem 0.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-radius: 4px;
}

.edit-tb {
  padding: 6px 16px;
}

.country-logo {
  margin-right: 10px;
  object-fit: cover;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  border: 1px solid #f2f2f2;
}

.lw-space td {
  padding: 8px 28px 8px 12px;
}

.primary-span-td {
  border-radius: 3px;
  background-color: #dee4eb;
  font-family: "CircularStd-Book, sans-serif" !important;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  float: left;
  display: inline;
  margin-bottom: 7.5px;
  color: #0f51a1;
  margin-right: 2px;
  width: fit-content;
}

.secondary-span-td-single {
  border-radius: 3px;
  background-color: #f2f2f2;
  font-family: "CircularStd-Book, sans-serif" !important;
  padding: 7px 20px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  float: left;
  display: inline;
  margin-bottom: 7.5px;
  color: #8a94a6;
  margin-right: 2px;
  width: fit-content;
  border-radius: 20px !important;
}

.primary-span-td:last-child {
  border-radius: 4px 20px 20px 4px;
}

.primary-span-td:first-child {
  border-radius: 20px 4px 4px 20px;
}

.page-desc {
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  margin-top: 3px;
  color: #8a94a6 !important;
}

.page-desc-left {
  font-size: 14px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #8a94a6 !important;
}

.top-open.show {
  display: block;
  top: auto !important;
}
.select-form-control {
  display: block;
  width: 85%;
  height: calc(1.5em + 0.75rem + -2px);
  padding: 0.375rem 0.25rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #8894a8;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.select-form-control {
  display: block;
  width: 85%;
  height: calc(1.5em + 0.75rem + -2px);
  padding: 0.375rem 0.25rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #8894a8;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.primary-span-td-single {
  border-radius: 20px !important;
}
.footer-form {
  text-align: center;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}
.form-row-table .has-error,
.form-row-table .has-batch {
  line-height: 1.5;
}
@media (max-width: 1024px) {
  .col-vd {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media (max-width: 768px) {
  .col-vd {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .action-col-lg {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .head-cat-col-lg {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 576px) {
  .action-col-lg {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .head-cat-col-lg {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 480px) {
  .action-col-lg {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .head-cat-col-lg {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .add-btn {
    margin-right: 6px;
    border-radius: 5px;
    border: solid 1px #ffffff;
    background-color: #0f51a1;
    padding: 8px 12px;
    font-family: "CircularStd-Book, sans-serif" !important;
    font-size: 12px;
    color: #ffffff;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}
