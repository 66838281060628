.settingsProfile .passwordGrid span {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #0f51a1;
  font-family: "CircularStd-Book, sans-serif" !important;
  position: absolute;
  right: 43%;
  top: 122%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
