.container-fluid-invitaion {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  padding: 15px;
}

input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #d1d3d1;
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #0f51a1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #0f51a1;
}
.center-header {
  text-align: center;
}

.center-logo {
  width: 22.5%;
}

.card-container-body {
  margin: 0% 8%;
}
.step-row {
  background: #0f51a1;
  color: white;
  padding: 12px;
  border-radius: 6px;
}

.steps-class-title {
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 15px;
  color: #ffffff;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 0rem;
  cursor: pointer;
}

.steps-class.active .steps-class-title {
  color: #ffd61b !important;
}

.register-head {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.09;
  letter-spacing: normal;
  font-family: "CircularStd-Bold, sans-serif" !important;
  color: #0a1f44;
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: center;
}

.pr-light {
  padding: 2% 15%;
}

.next-btn {
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 8px 32px;
}

.pr-bold {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.09;
  letter-spacing: normal;
  margin-top: 1rem;
  font-family: "CircularStd-Bold, sans-serif" !important;
  color: #0f51a1;
}

.input-radio {
  padding: 15px 20px;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
}

.primary-link {
  color: #0f51a1;
}
.row-mt-4 {
  margin-top: 2rem;
}
.dp-align-right {
  text-align: end;
}

.dp-align-left {
  text-align: start;
}

.dp-align-center {
  text-align: center;
}
#step-class-1 {
  text-align: left;
}
#step-class-2 {
  text-align: center;
}

#step-class-3 {
  text-align: right;
}

.steps-class-title i {
  margin-left: 12%;
}

.file-upload-btn-invitaion {
  margin-left: 15px;
  margin-top: 5%;
}

@media (max-width: 768px) {
  .center-logo {
    width: 95%;
  }

  .card-container-body {
    margin: 0% 0%;
  }

  .file-upload-btn-invitaion {
    margin-left: 0px;
    margin-top: 5%;
  }

  #step-class-1 {
    text-align: left;
    margin-bottom: 10px;
  }
  #step-class-2 {
    text-align: left;
    margin-bottom: 10px;
  }

  #step-class-3 {
    text-align: left;
    margin-bottom: 10px;
  }

  .pr-light {
    padding: 2% 5%;
  }
}
