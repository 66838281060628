.add-btn {
  margin-right: 12px;
  border-radius: 5px;
  border: solid 1px #ffffff;
  background-color: #0f51a1;
  padding: 7px 28px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 12px;
  color: #ffffff;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.user_add {
  margin-left: 7.5px;
  margin-right: 7.5px;
}

.footer-form {
  text-align: center;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}

.submit-btn {
  border-radius: 4px;
  margin-right: 6px;
  border: solid 1px #ffffff;
  background-color: #0f51a1;
  padding: 11px;
  width: 20%;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 14px;
  color: #ffffff;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.cancel-btn {
  margin-right: 6px;
  border-radius: 4px;
  border: solid 1px #8894a8;
  background-color: #ffffff;
  padding: 11px;
  width: 20%;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 14px;
  color: #8894a8;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.mob-hide {
  display: block;
}

.mob-show {
  display: none;
}

form.user_add .form-control-user {
  font-size: 14px;
  font-family: "CircularStd-Medium, sans-serif" !important;
  border-radius: 4px;
  padding: 0rem 0rem;
  color: #4a4a4a;
}

form.user_add .form-control-input {
  font-size: 14px;
  font-family: "CircularStd-Medium, sans-serif" !important;
  border-radius: 4px;
  padding: 0rem 0.25rem;
  color: #4a4a4a;
}

form.user_add .form-control-label {
  font-size: 12px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #8a94a6;
}

.img-col-user {
  margin-left: 15px;
  margin-top: 35px;
  min-height: 150px;
  border-radius: 4px;
}

.file-upload-btn {
  margin-left: 15px;
  margin-top: 5%;
}

.file-label {
  margin-right: 6px;
  border-radius: 5px;
  border: solid 1px #0f51a1;
  background-color: #ffffff;
  padding: 6px 15px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 12px;
  color: #0f51a1;
  cursor: pointer;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 0;
}

input[type="file"] {
  display: none;
}

.block-wd {
  padding-inline-end: 26px;
}

@media (max-width: 768px) {
  .cancel-btn,
  .submit-btn {
    width: 100%;
    margin-bottom: 10px;
  }

  .mob-hide {
    display: none;
  }

  .mob-show {
    display: block;
  }

  .img-col-user {
    margin-left: 0px !important;
    margin-top: 0% !important;
    float: left;
    margin-bottom: 2.5%;
  }
  .user-img-tile {
    width: 150px;
    height: 150px;
    text-align: center;
  }
  .user-img {
    max-width: 150px;
    max-height: 150px;
    border-radius: 4px;
  }

  .file-upload-btn {
    margin-left: 0px;
    margin-top: 10%;
    margin-right: 12px;
  }

  .file-label {
    margin-left: 12px;
    margin-right: 6px;
    border-radius: 5px;
    border: solid 1px #0f51a1;
    background-color: #ffffff;
    padding: 6px 15px;
    font-family: "CircularStd-Book, sans-serif" !important;
    font-size: 12px;
    color: #0f51a1;
    cursor: pointer;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 0;
  }

  .file-upload-btn a {
    margin-left: 12px;
  }
}
