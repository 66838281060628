.mainContactSet .card-main {
  padding: 30px;
}

.mainContactSet .card-main .card-header {
  border: none;
  padding: 0 !important;
}

.mainContactSet .card-main .card-body {
  padding: 20px 0 0 0 !important;
}

.mainContactSet .card-main .card-body label {
  font-weight: bold;
  color: #041e46;
  font-size: 14px;
  margin-bottom: 3px;
}

.mainContactSet .card-main .card-body .form-group {
  margin-bottom: 35px;
}

.mainContactSet .card-main .card-body .footer-form {
  text-align: left;
  padding: 10px 0 0;
}

.mainContactSet .card-main .card-body .col-md-6 {
  max-width: 434px;
}

.mainContactSet .card-main .card-body .footer-form button {
  /* width: 410px; */
  height: 45px;
  width: 190px;
}

.mainContactSet .card-main .card-header h6.font-book {
  font-size: 14px;
  color: #041e46;
}
