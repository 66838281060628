.testCustomCard{
    border-radius: 15px;
}

.testCustomCard img{
    border-radius: 15px;
}

.testCustomCard h3{
    font-weight: bold;
    font-size: 24px;
    color: #000;
}