.cat_add {
  margin-left: 0px;
  margin-right: 0px;
}

.cat_head {
  border-radius: 4px;
  border: solid 1px #e1e4e8;
  background-color: #ffffff;
  padding: 20px;
  /* height: 260px; */
  height: 230px;
  margin-bottom: 1rem;
  transition: 0.4s;
  position: relative;
}

.cat_head:hover {
  /* background: #F7F8F9; */
}

/* 07/06/22 */
.is_published {
  background: #f7f8f9;
}

.cat_head.Manage_Video_Category:hover {
  /* background: #F7F8F9; */
  transform: scale(1.06);
}

.cat_head.Manage_Video_Category.active {
  background: #f7f8f9;
}

.page-desc {
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  margin-top: 3px;
  color: #8a94a6 !important;
}

.page-desc-left {
  font-size: 14px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #8a94a6 !important;
}

.top-open.show {
  display: block;
  top: auto !important;
}

.head_name {
  font-family: "CircularStd-Medium, sans-serif" !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  color: #8a94a6;
  margin-bottom: 0;
}

.head_name.unpublished {
  color: #979797;
}

.head_name.published {
  color: #3ad219;
}

.add-btn {
  margin-right: 12px;
  border-radius: 5px;
  border: solid 1px #ffffff;
  background-color: #0f51a1;
  padding: 7px 28px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 12px;
  color: #ffffff;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.head_description {
  font-family: "CircularStd-Medium, sans-serif" !important;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #0a1f44;
}

.cat-desc-head {
  line-height: 24px;
  height: auto;
  margin-bottom: 50px;
}

h4.head_description.cat-desc-head.cursor-pointer {
  height: auto;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 160px;
  margin: 0;
  line-height: 16px;
}

.Manage_Video_Category span.badge_one {
  font-size: 10px;
  background: #8a94a6;
  border-radius: 3px;
  height: 16px;
  color: #f7f8f9;
  padding: 2px 5px;
  margin-left: 9px;
  font-weight: 100 !important;
  font-family: "CircularStd-Book, sans-serif" !important;
}

.action-col-lg {
  flex: 0 0 70%;
  max-width: 70%;
}

.head-cat-col-lg {
  flex: 0 0 30%;
  max-width: 30%;
}

.cat_desc {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5rem;
}

.cat_desc_btn {
  display: flex;
  flex-wrap: wrap;
}

.right-col {
  float: right;
}

.num_head {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-vd-nodata {
  text-align: center;
  position: absolute;
  top: 40%;
}

textarea.wt-resize {
  resize: none;
}

.cat_head .form-group {
  margin-bottom: 0rem;
}

.can-btn {
  margin-top: 1rem;
  border-radius: 3px;
  background-color: #fff;
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8a94a6;
  border: solid 1px #e1e4e8;
  padding: 6px 20px;
}

.save-btn {
  margin-top: 1rem;
  border-radius: 3px;
  background-color: #0f51a1;
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border: solid 1px #0f51a1;
  padding: 6px 28px;
  float: right;
}

.save-btn-error {
  margin-top: 0rem;
  border-radius: 3px;
  background-color: #0f51a1;
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border: solid 1px #0f51a1;
  padding: 6px 28px;
  float: right;
}

.can-btn-error {
  margin-top: 0rem;
  border-radius: 3px;
  background-color: #fff;
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8a94a6;
  border: solid 1px #e1e4e8;
  padding: 6px 20px;
}

.error-text-block {
  height: 36px;
}

.act-btn {
  margin-top: 1rem;
  border-radius: 3px;
  background-color: #fff;
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8a94a6;
  border: solid 1px #e1e4e8;
}

.act-btn img {
  margin-right: 4px;
}

.rt-mg {
  margin-right: 14px;
}

.rt-mg-2 {
  margin-right: 9px;
}

.date_head {
  flex: 0 0 50%;
  max-width: 50%;
  text-align-last: right;
}

form.cat_add .form-control-user {
  font-size: 14px;
  font-family: "CircularStd-Book, sans-serif" !important;
  border-radius: 4px;
  padding: 1.2rem 0.25rem;
  color: #8a94a6;
}

form.cat_add .form-control-user[readonly] {
  background: #fff;
  color: #8a94a6;
}

form.cat_add .form-control-label {
  font-size: 12px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #8a94a6;
}

.Manage_Video_Category .no-arrow.dp-right.dropdown {
  position: absolute;
  right: 20px;
  top: 15px;
}

.Manage_Video_Category .no-arrow.dp-right.dropdown button#dropdown-basic {
  border: 0;
  background: transparent;
  margin: 0;
  padding: 0;
  font-size: 25px;
  width: 30px;
}

.Manage_Video_Category
  .no-arrow.dp-right.show.dropdown
  .dropdown-staff.dropdown-menu {
  transform: translate3d(-95px, 35px, 0px) !important;
}

@media (max-width: 1366px) {
  .save-btn {
    margin-top: 2.5rem;
    border-radius: 3px;
    background-color: #0f51a1;
    font-size: 12px;
    font-weight: normal;
    font-family: "CircularStd-Book, sans-serif" !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: solid 1px #0f51a1;
    padding: 6px 24px;
    float: right;
  }
}

@media (max-width: 1280px) {
  .col-vd {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .cat_head {
    border-radius: 4px;
    border: solid 1px #e1e4e8;
    background-color: #ffffff;
    padding: 12px;
    /* height: 260px; */
    height: 230px;
    margin-bottom: 1rem;
  }
}

@media (max-width: 768px) {
  .cat_head {
    border-radius: 4px;
    border: solid 1px #e1e4e8;
    background-color: #ffffff;
    padding: 20px;
    /* height: 260px; */
    height: 230px;
    margin-bottom: 1rem;
  }

  .col-vd {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .head-cat-col-lg {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .action-col-lg {
    flex: 0 0 40%;
    max-width: 40%;
  }
}

@media (max-width: 576px) {
  .action-col-lg {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .head-cat-col-lg {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 480px) {
  .action-col-lg {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .head-cat-col-lg {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .add-btn {
    margin-right: 6px;
    border-radius: 5px;
    border: solid 1px #ffffff;
    background-color: #0f51a1;
    padding: 8px 12px;
    font-family: "CircularStd-Book, sans-serif" !important;
    font-size: 12px;
    color: #ffffff;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}
