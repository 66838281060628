.pre-desc {
  line-height: 24px;
  /* padding-top: 24px; */
  width: 484px;
  overflow: hidden;
  word-break: break-word;
  white-space: pre-line;
}

.pname-desc {
  width: 180px;
  overflow: hidden;
  word-break: break-word;
  white-space: pre-line;
}

.bold-desc {
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #0a1f44 !important;
}

.page-desc {
  font-size: 14px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #8a94a6 !important;
}

.bold-black {
  color: #0a1f44 !important;
}

.list-img {
  width: 56px;
  height: 56px;
}

.top-open.show {
  display: block;
  top: auto !important;
}

.add-btn {
  margin-right: 12px;
  border-radius: 5px;
  border: solid 1px #ffffff;
  background-color: #0f51a1;
  padding: 7px 28px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 12px;
  color: #ffffff;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.product_add {
  margin-left: 7.5px;
  margin-right: 7.5px;
  margin-top: 15px;
  margin-bottom: 7.5px;
}

.footer-form {
  text-align: center;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}

.submit-btn {
  border-radius: 4px;
  margin-right: 6px;
  border: solid 1px #ffffff;
  background-color: #0f51a1;
  padding: 12px;
  width: 20%;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 14px;
  color: #ffffff;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.cancel-btn {
  margin-right: 6px;
  border-radius: 4px;
  border: solid 1px #8894a8;
  background-color: #ffffff;
  padding: 11px;
  width: 20%;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 14px;
  color: #8894a8;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.mob-hide {
  display: block;
}

.mob-show {
  display: none;
}

textarea.wt-resize {
  resize: none;
}

form.product_add .form-control-product {
  font-size: 14px;
  font-family: "CircularStd-Book, sans-serif" !important;
  border-radius: 4px;
  padding: 0rem 0rem;
  color: #4a4a4a;
}

form.product_add .form-control-input {
  font-size: 14px;
  font-family: "CircularStd-Book, sans-serif" !important;
  border-radius: 4px;
  height: calc(1.5em + 0.75rem + 5px);
  padding: 0rem 0.25rem;
  color: #4a4a4a;
}

form.product_add .form-control-textarea {
  font-size: 14px;
  font-family: "CircularStd-Book, sans-serif" !important;
  border-radius: 4px;
  padding: 0.75em 0.25rem;
  color: #4a4a4a;
}

form.product_add .form-control-label {
  font-size: 12px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #8a94a6;
}

.img-col-product {
  margin-top: 10%;
  min-height: 150px;
  border-radius: 4px;
}

.product-img {
  width: 150px;
  height: 150px;
  border-radius: 8px;
}

.product-img-sm {
  width: 70px;
  height: 70px;
  border-radius: 8px;
}

.product-div-sm {
  width: 70px;
  height: 70px;
  border-radius: 8px;
  border: 1px solid #0f51a1;
  text-align: center;
  margin-top: 7.5px;
  background: #dee4ec;
  padding: 22px 0;
  cursor: pointer;
}

.img-count {
  font-size: 20px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #0a1f44;
}

.col-sm-img {
  margin-left: -10px;
}

.file-upload-btn-product {
  margin-left: 0px;
  margin-top: 5%;
}

.file-label {
  margin-right: 10px;
  border-radius: 5px;
  border: solid 1px #0f51a1;
  background-color: #ffffff;
  padding: 6px 15px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 12px;
  color: #0f51a1;
  cursor: pointer;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 0;
}

input[type="file"] {
  display: none;
}

.block-wd {
  padding-inline-end: 26px;
}

.gallery-footer {
  display: flex;
}

.img-col-product .imageBoxHolder {
  background: #ffffff;
  border: 1px solid #0f51a1;
  border-radius: 8px;
  width: 280px;
  height: 225px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #0f51a1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-upload-btn-product.uploadImageHolder .file-label {
  border-radius: 3px;
  width: 225px;
  height: 45px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #0f51a1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-upload-btn-product.uploadImageHolder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 18px;
}

.file-upload-btn-product.uploadImageHolder label.moreImage {
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  background-color: transparent;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productSubmit .footer-form {
  display: flex;
  justify-content: flex-end;
  padding: 0;
}

.otherImageHolder {
  margin-left: 14px;
}

.otherImageHolder img {
  width: 45px;
  height: 45px;
}

.otherImageHolder .product-div-sm {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productImageHolder {
  position: relative;
}

.productImage {
  position: relative;
  width: 56px;
  height: 56px;
}

.tableTextSplit {
  background: #0f51a1;
  border-radius: 3px;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 14px;
  color: #ffffff;
}

.otherImageCount {
  background: #0f51a1;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 0;
  right: -5px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-style: normal;
  font-weight: 450;
  font-size: 8px;
  line-height: 8px;
  display: flex;
  align-items: center;
  color: #ffffff;
  justify-content: center;
}

.addbatchImage {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  width: 215px;
  height: 200px;
  position: relative;
}

.addbatchImage img {
  width: 100%;
  height: 100%;
}

.addbatchImage p {
  font-family: "CircularStd-Book, sans-serif" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #8a94a6;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.datePicker {
  position: relative;
}

.datePicker .icon {
  position: absolute;
  bottom: 29px;
  right: 10px;
}

.inactiveButton.inactiveBatch {
  border: 1px solid #ff2323;
  border-radius: 3px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ff2323;
}

@media (max-width: 1280px) {
  .col-sm-img {
    margin-left: 0px;
  }
}

@media (max-width: 1024px) {
  .col-sm-img {
    margin-left: 32px;
  }

  .img-col-product {
    margin-left: -25px;
  }
}

@media (max-width: 768px) {
  .img-col-product {
    margin-left: 0px;
  }

  .cancel-btn,
  .submit-btn {
    width: 100% !important;
    margin-bottom: 10px;
  }

  .mob-hide {
    display: none;
  }

  .mob-show {
    display: block;
  }

  .img-col-product {
    margin-left: 0px !important;
    margin-top: 0% !important;
    float: left;
    margin-bottom: 2.5%;
  }

  .user-product {
    width: 150px;
    height: 150px;
    border-radius: 4px;
  }

  .file-upload-btn-product {
    margin-left: 0px;
    margin-top: 10%;
    margin-right: 12px;
  }

  .file-label {
    margin-left: 12px;
    margin-right: 6px;
    border-radius: 5px;
    border: solid 1px #0f51a1;
    background-color: #ffffff;
    padding: 6px 15px;
    font-family: "CircularStd-Book, sans-serif" !important;
    font-size: 12px;
    color: #0f51a1;
    cursor: pointer;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 0;
  }

  .file-upload-btn a {
    margin-left: 12px;
  }
}
