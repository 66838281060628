.rows {
  width: 100%;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
  display: grid;
  gap: 20px;
  padding-left: 10px;

  grid-template-columns: auto auto auto;
}

.rows .cols-md-3 {
  /* max-width: 85% !important; */
  width: 350px;
}

.second-rows {
  padding-top: 20px;
  width: 100%;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
  display: grid;
  row-gap: 20px;
  column-gap: 20px;
  grid-template-columns: auto auto;
}

.text-area {
  width: 100%;
  padding-top: 20px;
  padding-right: 10px;
}

.text-area-label {
  color: #000;
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
}

.buttons-container {
  padding-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.input-rightside {
  margin-left: 15px;
}

@media (max-width: 1450px) {
  .rows {
    width: 100%;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
    display: grid;
    gap: 20px;
    padding-left: 10px;

    grid-template-columns: auto auto;
  }

  .rows .cols-md-3 {
    /* max-width: 85% !important; */
    /* width: 350px; */
  }
  .second-rows {
    padding-top: 20px;
    width: 100%;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    grid-template-columns: auto auto;
  }

  .text-area {
    width: 100%;
    padding-top: 20px;
    padding-right: 10px;
  }
}

@media (max-width: 1100px) {
  .rows {
    width: 100%;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
    display: grid;
    gap: 20px;
    padding-left: 10px;

    grid-template-columns: auto auto;
  }

  .rows .cols-md-3 {
    /* max-width: 85% !important; */
    width: 250px;
  }
  .second-rows {
    padding-top: 20px;
    width: 100%;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    grid-template-columns: auto auto;
  }

  .text-area {
    width: 100%;
    padding-top: 20px;
    padding-right: 10px;
  }
}

@media (max-width: 885px) {
  .rows {
    width: 100%;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
    display: grid;
    gap: 20px;
    padding-left: 10px;

    grid-template-columns: auto auto;
  }

  .rows .cols-md-3 {
    /* max-width: 85% !important; */
    width: 180px;
  }
  .second-rows {
    padding-top: 20px;
    width: 100%;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    grid-template-columns: auto;
  }

  .text-area {
    width: 100%;
    padding-top: 20px;
    padding-right: 10px;
  }
  .input-rightside {
    margin-left: 0px;
  }
}

@media (max-width: 480px) {
  .rows {
    width: 100%;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
    display: grid;
    gap: 20px;
    padding-left: 10px;

    grid-template-columns: auto auto;
  }

  .rows .cols-md-3 {
    /* max-width: 85% !important; */
    width: 150px;
  }
  .second-rows {
    padding-top: 20px;
    width: 100%;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    grid-template-columns: auto;
  }

  .text-area {
    width: 100%;
    padding-top: 20px;
    padding-right: 10px;
  }
  .input-rightside {
    margin-left: 0px;
  }
}
@media (max-width: 380px) {
  .rows {
    width: 100%;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
    display: grid;
    gap: 20px;
    padding-left: 10px;

    grid-template-columns: auto;
  }

  .rows .cols-md-3 {
    /* max-width: 85% !important; */
    width: auto;
  }
  .second-rows {
    padding-top: 20px;
    width: 100%;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    grid-template-columns: auto;
  }

  .text-area {
    width: 100%;
    padding-top: 20px;
    padding-right: 10px;
  }
  .input-rightside {
    margin-left: 0px;
  }
}
