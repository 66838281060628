.graph-card {
  height: auto;
}

.graph-card .card-header {
  height: 50px;
}

.shadow-border {
  border: solid 1px #e1e4e8;
  border-radius: 4px !important;
}

.lf-mg {
  margin-left: 7.5px;
}

.description {
  margin-top: 12px;
}

.description .head {
  color: #0a1f44;
  font-weight: bold;
  font-family: "CircularStd-Book, sans-serif";
  font-size: 20px;
}

.css-1sm5kh9-singleValue {
  color: #8a94a6;
  margin-left: 20px !important;
}

.dropdwn-dash {
  border: 0px solid #fff !important;
  box-shadow: none !important;
  margin-top: 0.5rem;
  letter-spacing: 0.17px !important;
  margin-right: 0px !important;
}

.dropdwn-dash span {
  color: #8a94a6 !important;
}

.dropdwn-dash:hover {
  border: 0px solid #fff !important;
  box-shadow: none !important;
}

.dropdwn-dash:active,
.dropdwn-dash:focus,
.dropdwn-dash.focus {
  border: 0px solid #fff !important;
  box-shadow: none !important;
  background: #fff !important;
  color: #8a94a6 !important;
}

.dropdwn-dash:not(:disabled):not(.disabled):active,
.dropdwn-dash:not(:disabled):not(.disabled).active,
.show > .dropdwn-dash.dropdown-toggle {
  border: 0px solid #fff !important;
  box-shadow: none !important;
  background: #fff !important;
  color: #8a94a6 !important;
}

#SvgjsSvg1844 {
  margin-top: -22px;
}

.area-graph .apexcharts-canvas {
  margin-top: -22px;
}

#apexchartsapexchartxexample {
  margin-top: -15px;
}

.dropdwn-dash img {
  margin-left: 6px !important;
}

.dropdwn-dash span {
  font-size: 12px;
  font-weight: 400 !important;
}

.dp-end-right {
  padding-right: 0px;
}

.dropdwn-dash-menu {
  min-width: 7rem !important;
}

.dropdwn-dash-menu .dropdown-item {
  padding: 0.25rem 1rem;
}

.today-count {
  font-family: "CircularStd-Book, sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0;
  color: #8a94a6;
  margin-bottom: 1rem !important;
}

.today-count img {
  margin-left: 12px;
}

.css-ggxzqb-indicatorContainer,
.css-se5mpg-indicatorContainer {
  padding: 2px !important;
  width: 20px !important;
}

/* .apexcharts-canvas {
  position: relative;
  user-select: none;
  height: 400px !important;
} */
/* .counterfeit-graph .apexcharts-legend {
  position: relative !important;
  left: 130px !important;
  top: 50% !important;
} */
/* .apexcharts-svg,
.apexcharts-svg foreignObject {
  height: 400px !important;
} */
.top-scanned-chart.apexcharts-yaxis-label {
  font-size: 11px !important;
}

.mtc-2 {
  padding-top: 2rem;
}

.plus-success {
  font-family: "CircularStd-Book, sans-serif";
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: #00cd98;
  margin-bottom: 0rem;
}

.minus-danger {
  font-family: "CircularStd-Book, sans-serif";
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: #f64000;
  margin-bottom: 0rem;
}

.map-overlay {
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  margin-right: 20px;
  /* font-family: Arial, sans-serif; */
  overflow: auto;
  border-radius: 3px;
}

.active-country {
  padding: 4px;
}

.active-country:hover {
  border: solid 1px #e1e4e8;
  border-radius: 4px !important;
}

#legend {
  padding: 10px;
  line-height: 18px;
  min-height: 150px;
  margin-bottom: 40px;
  width: 200px;
}

.legend-key {
  font-family: "CircularStd-Book, sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #0a1f44;
}

.legend-value {
  float: right;
  font-family: "CircularStd-Book, sans-serif";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: right;
  color: #8a94a6;
}

.custom-marker {
  border-radius: 50%;
  animation: scaleIn 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
}

.mapboxgl-ctrl-bottom-left {
  display: none;
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

.mapContainer.geo-chart canvas.mapboxgl-canvas {
  background: url(../../../assets/img/map-checks-pattern.png);
}

.plus-success img,
.minus-danger img {
  padding-right: 15px;
  padding-bottom: 4px;
}

.plus-success,
.minus-danger {
  padding-top: 8px;
}

.no-data .apexcharts-legend {
  left: 0 !important;
}

.globeHolder {
  width: 26px;
  height: 26px;
  margin-right: 14px;
}

.globeHolder img {
  width: 100%;
}
