.user_add {
  margin-left: 7.5px;
  margin-right: 7.5px;
}

.footer-form {
  text-align: center;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}

.skeleton-bottom {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mob-hide {
  display: block;
}

.mob-show {
  display: none;
}

.action-btn {
  margin-right: 12px;
  border-radius: 5px;
  border: solid 1px #ffffff !important;
  background-color: #0f51a1 !important;
  padding: 7px 40px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 12px;
  color: #ffffff !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

form.user_add .form-control-user {
  font-size: 14px;
  font-family: "CircularStd-Medium, sans-serif" !important;
  border-radius: 4px;
  padding: 0rem 0rem;
  color: #4a4a4a;
}

form.user_add .form-control-input {
  font-size: 14px;
  font-family: "CircularStd-Medium, sans-serif" !important;
  border-radius: 4px;
  padding: 0rem 0.25rem;
  color: #4a4a4a;
}

form.user_add .form-control-user[readonly] {
  background: #fff;
  color: #8a94a6;
}

form.user_add .form-control-label {
  font-size: 12px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #8a94a6;
}

.img-col-user {
  margin-left: 15px;
  height: 100%;
  margin-top: 35px;
}
.view-img-user {
  display: flex;
  justify-content: center;
}
.user-img-tile {
  width: 150px;
  height: 150px;
  text-align: center;
}
.user-img {
  max-width: 150px;
  max-height: 150px;
  border-radius: 4px;
}

.file-upload-btn {
  margin-left: 15px;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.img-col-user .file-upload-btn img {
  margin: 0;
}

.pagination {
  display: inline-block;
}
.right {
  text-align: right;
}
.pagination a {
  font-size: 14px;
  color: #8a94a6;
  float: left;
  margin: 0px 2px;
  padding: 2px 10px;
  text-decoration: none;
  border: 1px solid #e1e4e8;
  border-radius: 3px;
  background: #fff;
  font-family: "CircularStd-Book, sans-serif" !important;
}

.pagination a.active {
  border: 1px solid #8a94a6;
  color: #0a1f44;
}

.pagination a:hover:not(.active) {
  border: 1px solid #8a94a6;
}

.user_add.newui .form-group span {
  color: #4a4a4a;
  font-style: normal;
  font-size: 14px;
  line-height: 1;
  font-family: "CircularStd-Medium, sans-serif" !important;
  margin-bottom: 30px;
  padding-left: 27px;
}

.user_add.newui .form-group .form-control-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: #8a94a6;
  display: flex;
  align-items: center;
}

.user_add.newui .form-group .form-control-label img {
  margin-right: 10px;
  width: 15px;
}

.user_add.newui .img-col-user2 {
  margin-top: 45px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .img-col-user {
    margin-left: 0px;
    margin-top: 0%;
    margin-bottom: 2.5%;
  }

  .mob-hide {
    display: none;
  }

  .mob-show {
    display: block;
  }
}
