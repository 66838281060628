.col-md-12.footer-formCZ {
  padding: 20px 12px 20px !important;
}

.col-md-12.footer-formCZ button {
  margin-right: 10px;
}
.table-responsive td {
  color: #8a94a6;
}

.aboutus-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 10px 35px 10px;
}

.table-responsive td span.scan_count {
  color: #0f51a1;
  font-family: "CircularStd-Book, sans-serif";
  border-bottom: 1px solid #0f51a1;
  position: relative;
  margin-left: 6px;
}

.table-responsive td span.scan_count::before {
  content: ".";
  position: absolute;
  transform: translate(-50%, -50%);
  top: 30%;
  left: -4px;
  color: #8a94a6;
  font-size: 16px;
}

.table-responsive td .purchased_status {
  width: 48px;
  height: 24px;
  color: #8a94a6;
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  font-family: "CircularStd-Book, sans-serif";
  background: rgba(184, 184, 184, 0.1);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-responsive td .purchased_status.yes {
  color: #3ad219;
  background: rgb(58 210 25 / 10%);
}

.table-responsive td .purchased_status.no {
  color: #ff2323;
  background: rgb(255 35 35 / 10%);
}

.table-responsive td.location_scan_td {
  min-width: 300px;
  max-width: 300px;
  width: 300px;
}

.alert_table p.severity_status.mild {
  border-radius: 3px;
  background: rgb(248 156 65 / 10%);
  display: inline-block;
  color: #f89c41;
  font-family: "CircularStd-Book, sans-serif";
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  padding: 4px 11px;
  margin: 16.5px 0;
}

.alert_table p.severity_status.potential {
  border-radius: 3px;
  background: rgba(247, 185, 185, 0.974);
  display: inline-block;
  color: #ff2323;
  font-family: "circular";
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  padding: 4px 11px;
  margin: 16.5px 0;
}

.alert_table p.severity_status.potential {
  border-radius: 3px;
  background: rgb(255 35 35 / 10%);
  display: inline-block;
  color: #ff2323;
  font-family: "circular";
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  padding: 4px 11px;
  margin: 16.5px 0;
}

.scan_rules_filter {
  margin-left: 16px;
}
.scan_rules_filter .button {
  color: #8a94a6;
  text-align: center;
  font-family: "CircularStd-Book, sans-serif";
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  border-radius: 8px;
  border: 1px solid #e1e4e8;
  background: #fff;
  padding: 5px 12px;
  margin-right: 8px;
  outline: 0;
  font-size: 12px;
  text-decoration: none;
}

.scan_rules_filter .button.active {
  border: 1px solid #0f51a1;
  background: #0f51a1;
  color: #fff;
}

.add_scan_rules .form_title {
  color: #0a1f44;
  /* font-family: "circular"; */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 45px;
  margin-bottom: 10px;
}

.buttonGroup_custom {
}
.buttonGroup_custom .btn-group {
  padding: 0px 4px;
  border-radius: 8px;
  background: #0f51a1;
  height: 40px;
  display: flex;
  align-items: center;
}
.buttonGroup_custom .btn-group button {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 5px;
  background: transparent;
  height: 32px;
  width: 166px;
  color: rgb(255 255 255 / 50%);
  font-family: "circular";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.buttonGroup_custom .btn-group button.active {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 5px;
  background: #fff;
  height: 32px;
  width: 166px;
  color: #0f51a1;
  font-family: "circular";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border-radius: 5px !important;
}

.buttonGroup_custom .btn-group button:focus {
  box-shadow: none;
}

.add_scan_rules .form-group {
  margin-bottom: 15px;
}

.view_alert_modal {
}

.view_alert_modal .modal-header {
  padding: 10px 20px;
}

.view_alert_modal .form-group {
  margin-top: 30px;
}
.view_alert_modal .form-group .form-control-label {
  color: #8a94a6;
  font-family: "CircularStd-Book, sans-serif";
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.view_alert_modal .form-group .form-control-label img {
  margin-right: 10px;
}
.view_alert_modal .form-group span {
  color: #4a4a4a;
  font-family: "CircularStd-Medium, sans-serif" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-left: 25px;
}

.view_alert_modal .form-group span.status.mild {
  border-radius: 3px;
  border: 0px solid rgb(248 156 65 / 10%);
  background: rgb(248 156 65 / 10%);
  width: 68px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f89c41;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.view_alert_modal .form-group span.status.potential {
  border-radius: 3px;
  border: 0px solid rgb(255 35 35 / 10%);
  background: rgb(255 35 35 / 10%);
  display: inline-block;
  color: #ff2323;
  width: 68px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.view_alert_modal .modal-dialog {
  max-width: 875px;
}

.view_alert_modal .modal-body {
  padding-top: 0;
}

.view_alert_modal .table_wrapper {
  margin-top: 50px;
  height: 325px;
  overflow: auto;
}
.view_alert_modal .table_wrapper table {
}
.view_alert_modal .table_wrapper {
  border-top: 1px solid #f2f2f2;
  margin-bottom: 20px;
}
.view_alert_modal .table_wrapper tr th {
  color: #0a1f44;
  font-family: "CircularStd-Medium, sans-serif" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  z-index: 1;
}
.view_alert_modal .table_wrapper tr td {
  color: #8a94a6;
  font-family: "CircularStd-Book, sans-serif";
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}

.view_alert_modal .table_wrapper tr td span {
  color: #0f51a1;
  font-family: "CircularStd-Book, sans-serif";
  border-bottom: 1px solid #0f51a1;
  position: relative;
  margin-left: 6px;
  cursor: pointer;
}

.view_alert_modal .table_wrapper tr td span::before {
  content: ".";
  position: absolute;
  transform: translate(-50%, -50%);
  top: 30%;
  left: -4px;
  color: #8a94a6;
  font-size: 16px;
}

/* .scanlistingdetails_table tr {
  position: relative;
} */
/* 
.scanlistingdetails_table tr th:first-child {
  position: absolute;
  left: 0;
  top: auto;
}
.scanlistingdetails_table tr td:first-child {
  position: absolute;
  left: 0;
  top: auto;
}

.scanlistingdetails_table thead {
  position: unset;
}

.scanlistingdetails_table {
  width: 90%;
  overflow-x: scroll;
  margin-left: 6em;
  overflow-y: hidden!important;
} */
