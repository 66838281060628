.noActive {
    background: transparent;
}

.collapse-item.subchild {
    display: flex;
    align-items: center;
}

.collapse-item.subchild img {
    position: absolute;
    right: 5px;
}

.sidebar-dark .nav-item.active .nav-link.nav-collapse-link {
    background-color: unset !important;
}