button.pushButtonCZ {
  background: #0f51a1;
  color: white !important;
  width: 120px;
}

.proAddCZ .modal-header {
  padding-left: 30px;
}

.proAddCZ .modal-body {
  padding: 0px 15px;
}

.proAddCZ .modal-body .row {
  margin: 0;
}

.proAddCZ .modal-body form.product_add.proAdd {
  margin: 20px 0 10px;
}

.proAddCZ .modal-body .row .col-md-12 {
  padding: 15px 10px 0;
}

.proAddCZ .modal-body .row .col-md-12 .form-group {
  margin: 0;
}

.proAddCZ .modal-body .row .col-md-12 textarea#subject {
  min-height: 280px;
  font-family: "CircularStd-Book, sans-serif" !important;
}

.proAddCZ .modal-body .row .col-md-12.footer-form {
  text-align: left;
  padding-top: 5px;
  padding-bottom: 20px;
}

.table-responsive td {
  color: #8a94a6;
}

.pushNotify .dropdown-item.disabled,
.pushNotify .dropdown-item:disabled {
  color: #bebec3 !important;
  cursor: not-allowed;
}
