.col-md-12.footer-formCZ {
  padding: 20px 12px 20px !important;
}

.col-md-12.footer-formCZ button {
  margin-right: 10px;
}
.table-responsive td {
  color: #8A94A6;
}
