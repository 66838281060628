.cat_add {
  margin-left: 0px;
  margin-right: 0px;
}
.page-desc {
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  margin-top: 3px;
  color: #8a94a6 !important;
}
.error-text-video {
  padding-top: 5px;
}

.page-desc-left {
  font-size: 14px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #8a94a6 !important;
}

.top-open.show {
  display: block;
  top: auto !important;
}

.add-btn {
  margin-right: 12px;
  border-radius: 5px;
  border: solid 1px #ffffff;
  background-color: #0f51a1;
  padding: 7px 28px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 12px;
  color: #ffffff;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.head_name {
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #8a94a6;
}

.action-col-lg {
  flex: 0 0 70%;
  max-width: 70%;
}

.head-cat-col-lg {
  flex: 0 0 30%;
  max-width: 30%;
}

.cat_desc_btn {
  display: flex;
  flex-wrap: wrap;
}

.num_head {
  flex: 0 0 50%;
  max-width: 50%;
}

textarea.wt-resize {
  resize: none;
}

.can-btn-table {
  border-radius: 3px;
  background-color: #fff;
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8a94a6;
  margin-right: 6px;
  border: solid 1px #e1e4e8;
  padding: 6px 20px;
}

.save-btn-table {
  border-radius: 3px;
  background-color: #0f51a1;
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border: solid 1px #0f51a1;

  margin-right: 6px;
  padding: 6px 29px;
}

.act-btn {
  margin-top: 1rem;
  border-radius: 3px;
  background-color: #fff;
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8a94a6;
  border: solid 1px #e1e4e8;
}

.act-btn img {
  margin-right: 4px;
}

.date_head {
  flex: 0 0 50%;
  max-width: 50%;
}

form.cat_add .form-control-user {
  font-size: 14px;
  font-family: "CircularStd-Book, sans-serif" !important;
  border-radius: 4px;
  padding: 1.2rem 0.25rem;
  color: #8a94a6;
}

form.cat_add .form-control-user[readonly] {
  background: #fff;
  color: #8a94a6;
}

form.cat_add .form-control-label {
  font-size: 12px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #8a94a6;
}

.form-row-table {
  background: #f6f7fb;
}

.form-name {
  display: block;
  width: 70%;
  height: 30px;
  padding: 0.375rem 0.25rem;
  font-size: 14px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-radius: 4px;
}

.form-link {
  display: block;
  width: 100%;
  height: 30px;
  padding: 0.375rem 0.25rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  font-family: "CircularStd-Book, sans-serif" !important;
  color: #6e707e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-radius: 4px;
}

.edit-tb {
  padding: 6px 16px;
}

@media (max-width: 1024px) {
  .col-vd {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media (max-width: 768px) {
  .col-vd {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .action-col-lg {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .head-cat-col-lg {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 576px) {
  .action-col-lg {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .head-cat-col-lg {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 480px) {
  .action-col-lg {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .head-cat-col-lg {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .add-btn {
    margin-right: 6px;
    border-radius: 5px;
    border: solid 1px #ffffff;
    background-color: #0f51a1;
    padding: 8px 12px;
    font-family: "CircularStd-Book, sans-serif" !important;
    font-size: 12px;
    color: #ffffff;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}
