.modal-full-body {
  padding: 0rem !important;
}

.modal-full-body .close {
  padding-right: 0.35rem !important;
  padding-left: 0.35rem !important;
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  background-color: white;
  opacity: 1;
  position: absolute;
  left: 92%;
  top: 2%;
  color: #8a94a6bd;
  font-size: 27px;
  font-weight: 200;
  border-radius: 2px;
}

.modal-full-body .close:focus {
  outline: 0 !important;
}

.image-modal-view {
  width: 100%;
  height: 452px;
}

.left-ar {
  top: 45%;
  position: absolute;
  left: 2%;
}
.right-ar {
  top: 45%;
  left: 92%;
  position: absolute;
}
.footer-img {
  width: 70px;
  height: 70px;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 6px;
}

.footer-img:hover {
  /* border: solid 2px #0f51a1; */
}

.gallery-trash {
  position: absolute;
  /* margin-left: -5.5%; */
  margin-left: 0px;
  right: 0px;
  z-index: 1;
}

.product-images-list {
  cursor: pointer !important;
  position: relative;
}

.product-images-list:hover {
  border: solid 2px #0f51a1;
}

#hide-del {
  display: none;
}

/* .footer-img:hover ~ #hide-del {
  display: initial;
} */
.product-images-list:hover #hide-del {
  display: inline-block;
}

.featured-image {
  bottom: 14px;
  position: absolute;
  /* margin-left: -14.75%; */
  margin-left: 0px;
  left: 0px;
}
.featured-image i {
  color: white;
}
.center-item {
  justify-content: center !important;
}
