.container-fluid-invitaion {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  padding: 15px;
}

#wrapper #content-wrapper #content {
  height: 100vh;
}

.exportRow {
  background: #ffffff;
  border: 1px solid #e1e4e8;
  border-radius: 4px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.exportRow .button_grp {
  background: #ffffff;
  border: 1px solid #e1e4e8;
  border-radius: 3px;
  width: 398px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  overflow: hidden;
}

.exportRow .button_grp button {
  flex: 1 1;
  color: #8a94a6;
  border-radius: 3px;
  border: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  background: #fff;
  height: -webkit-fill-available;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exportRow .button_grp button.active {
  background: #0f51a1;
  color: #ffffff;
}

.exportRow .button_grp button:hover {
  background: #f7f7f7;
}

.exportRow .button_grp button.active:hover {
  background: #0f51a1;
  color: #ffffff;
}

.exportRow .button_grp button:active {
  background: #0f51a1;
  color: #ffffff;
}

.exportRow .datePickerBox {
  margin-left: 25px;
  position: relative;
}

.exportRow img.calendarIcon {
  position: absolute;
  width: 25px;
  /* height: 30px; */
  transform: translate(-50%, -50%);
  top: 50%;
  left: 25px;
}

.exportRow .datePickerBox input {
  background: #ffffff;
  border: 1px solid #e1e4e8;
  border-radius: 3px;
  width: 262px;
  height: 38px;
  padding: 10px 15px;
  color: #333333;
  font-weight: 500;
  font-size: 14px;
  padding-left: 40px;
}

.exportRow .exportbtn {
  background: #0f51a1;
  border: 1px solid #0f51a1;
  border-radius: 3px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  height: 36px;
  width: 100px;
  outline: 0;
  transition: 0.1s;
}

.exportRow .exportbtn:hover {
  background: #0e488f;
}

.exportRow .exportbtn:active {
  background: #0c3e7a;
}

.graphDataCard {
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.graphDataCard p {
  color: #000000;
  opacity: 0.8;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  font-family: "CircularStd-Book, sans-serif" !important;
  margin-bottom: 4px;
}

.graphDataCard h5 {
  color: #000000;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  font-family: "CircularStd, sans-serif";
  margin-bottom: 18px;
}

.graphDataCard .arrowBox span {
  color: #000000;
  font-style: normal;
  font-size: 12px;
  margin-left: 8px;
}

.graphDataCard .chartIcon img {
  width: 100%;
}

.tableCustom {
  background: #ffffff;
  border: 1px solid #e1e4e8 !important;
  border-radius: 4px;
  margin-top: 10px;
  overflow: hidden;
}

.border-radius_4 {
  border-radius: 4px;
}

@media (max-width: 1145px) {
  .exportRow .datePickerBox input {
    width: 180px;
  }

  .exportRow .button_grp {
    width: 280px;
  }

  .exportRow .datePickerBox {
    margin-left: 15px;
  }
}

@media (max-width: 940px) {
  .exportRow {
    flex-direction: column;
  }

  .exportRow .button_grp {
    width: 100%;
    overflow: unset;
  }

  .exportRow .datePickerBox {
    margin-left: 15px;
    width: 100%;
  }

  .exportRow .datePickerBox input {
    width: 100%;
  }

  .exportRow .d-flex.align-items-center {
    width: 100%;
    margin-bottom: 15px;
  }

  .exportRow .exportbtn {
    width: 100%;
  }
}

@media (max-width: 509px) {
  .exportRow .d-flex.align-items-center {
    flex-direction: column;
  }

  .exportRow .datePickerBox {
    margin-left: 0;
    width: 100%;
    margin-top: 15px;
  }

  .exportRow .button_grp button {
    height: 36px;
  }
}

.exportRow.clientDropdown .dropdown .dropdown-menu {
  line-height: 1.5;
  max-height: 210px;
  overflow: auto;
  min-height: 50px;
}

.exportRow .datePickerBox {
  margin-right: 25px;
}

.exportRow.clientDropdown {
  justify-content: start;
}

.exportRow.clientDropdown .filterRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
