.color-primary {
  color: #0f51a1 !important;
}

.sidemenu-btn {
  margin-right: 12px;
  border-radius: 5px;
  border: solid 1px #0f51a1;
  background-color: #ffffff;
  padding: 6px 10px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 12px;
  color: #0f51a1;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.sidemenu-btn:hover {
  margin-right: 12px;
  border-radius: 5px;
  border: solid 1px #0f51a1 !important;
  background-color: #ffffff !important;
  padding: 6px 10px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 12px;
  color: #0f51a1 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.map-icon-hover {
  width: 20px;
}

.main-marker {
  width: 30px;
}

.map-icon-hover:hover .map-icon-title {
  display: block;
}

.map-icon-title {
  display: none;
  background: #fff;
  width: 210px;
  position: absolute;
  bottom: 109%;
  right: -50%;
  transform: translateX(57%);
  padding: 8px 8px;
  z-index: 1;
  border: 1px solid #2851a0;
  font-size: 12px;
  border-radius: 4px;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  text-align: left;
}

.map-icon-title::after {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 7.5px 0 7.5px;
  border-color: #000 transparent transparent transparent;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.ril__caption,
.ril__toolbar {
  background-color: transparent;
}

.ril__navButtonPrev {
  /* left: 33.33% !important; */
  background: url(../../../assets/img/slider-left.svg) no-repeat 50% !important;
}

.ril__navButtonNext {
  /* right: 33.33% !important; */
  background: url(../../../assets/img/slider-right.svg) no-repeat 50% !important;
}

.ril__image,
.ril__imageNext,
.ril__imagePrev {
  margin: auto;
  max-width: 100% !important;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  -ms-touch-action: none;
  touch-action: none;
}

.ReactModal__Overlay {
  background-color: #000000c2 !important;
}

.ReactModal__Content {
  height: 500px !important;
  border-radius: 3px;
}

.ril__closeButton {
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PHBhdGggZD0iTTEgM2wxLjI1LTEuMjUgNy41IDcuNSA3LjUtNy41TDE4LjUgMyAxMSAxMC41bDcuNSA3LjUtMS4yNSAxLjI1LTcuNS03LjUtNy41IDcuNUwxIDE4bDcuNS03LjVMMSAzeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==)
    no-repeat 50%;
  /* right: 34.5%; */
  /* position: absolute; */
}

.ril__navButtons {
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 34px !important;
  height: 34px !important;
  padding: 3px 6px !important;
  margin: auto;
  cursor: pointer;
  opacity: 1;
}

/* .marker {
  display: block;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
} */
/* .sidebarStyle {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
}*/

/* .mapContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 200px;
} */
/* .sidebarStyle {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
} */
.mapboxgl-canvas-container {
  height: 100vh;
}

.geo-chart .mapboxgl-canvas-container {
  height: 400px;
}

/* .mapboxgl-canvas{
  height:7000px!
} */
/* .dropdown-staff.dropdown-menu {
  transform: translate3d(5px, 35px, 0px) !important;
} */
.dropdown-staff .dropdown-item {
  background-color: #fff;
}

.assigneRow {
  display: flex;
  align-items: center;
  justify-content: end;
}
