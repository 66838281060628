@media (max-width: 5120px) {
  .col-vdsf {
    flex: 0 0 6.25%;
    max-width: 6.25%;
  }
}
@media (max-width: 4096px) {
  .col-vdsf {
    flex: 0 0 6.667%;
    max-width: 6.667%;
  }
}
@media (max-width: 3840px) {
  .col-vdsf {
    flex: 0 0 7.14%;
    max-width: 7.14%;
  }
}
@media (max-width: 2560px) {
  .col-vdsf {
    flex: 0 0 10%;
    max-width: 10%;
  }
}

@media (max-width: 1920px) {
  .col-vdsf {
    flex: 0 0 14.28%;
    max-width: 14.28%;
  }
}

@media (max-width: 1680px) {
  .col-vdsf {
    flex: 0 0 16.33%;
    max-width: 16.33%;
  }
}

@media (max-width: 1440px) {
  .col-vdsf {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
.cat_add {
  margin-left: 0px;
  margin-right: 0px;
}
.staff_head {
  border-radius: 4px;
  border: solid 1px #e1e4e8;
  background-color: #ffffff;
  padding: 20px 5px 20px 5px;
  height: 350px;
  margin-bottom: 1rem;
}

.col-vd-nodata {
  text-align: center;
  position: absolute;
  top: 40%;
}
input.disable-write {
  background: #f2f2f2 !important;
  color: #8a94a6 !important;
}

.css-1o7fr5x-singleValue {
  color: #8a94a6 !important;
}

.footer-form-card {
  text-align: center;
  margin-top: 1.25%;
  margin-bottom: 1.25%;
}

.center {
  text-align: center;
}

.sf_name {
  font-family: "CircularStd-Medium, sans-serif" !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 14px;
  letter-spacing: normal;
  color: #0a1f44;
  margin-top: 12px;
}

.sf_desc {
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 12px;
  letter-spacing: normal;
  color: #8a94a6;
}

.sf_details {
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #8a94a6;
  margin-right: 7.5px;
  height: 20px;
}

.tp-4 {
  margin-top: -6px;
}

.mtb-2 {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.mtb-1 {
  margin-top: 0rem;
  margin-bottom: 0.5rem;
}
.cl_desc {
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 12px;
  letter-spacing: normal;
  color: #8a94a6;
}

.css-1v99tuv {
  color: hsl(0, 0%, 50%) !important;
}

.css-1gpjby2 {
  opacity: 0.5;
}

.cl_name_2 {
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 14px;
  letter-spacing: normal;
  color: #8a94a6;
}

.cl_desc_2 {
  font-family: "CircularStd-Medium, sans-serif" !important;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 14px;
  letter-spacing: normal;
  color: #0a1f44;
}

.pd-col {
  padding-right: 0.35rem;
  padding-left: 0.25rem;
}

.pd-col-2 {
  padding-right: 0.5rem;
  padding-left: 1.75rem;
}

.pd-col-sm {
  padding-right: 0.35rem;
  padding-left: 0.35rem;
}
#sm-search-filter {
  width: 15rem;
}
.list-view-img-staff {
  width: 45px;
  height: 47px;
  margin-right: 6px;
  object-fit: cover;
  border-radius: 50%;
}

.grid-view-img-staff {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 50%;
}

.page-desc {
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  margin-top: 3px;
  color: #8a94a6 !important;
}

.page-desc-left {
  font-size: 14px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #8a94a6 !important;
}

.top-open.show {
  display: block;
  top: auto !important;
}

.head_name {
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #8a94a6;
}

.add-btn {
  margin-right: 12px;
  border-radius: 5px;
  border: solid 1px #ffffff;
  background-color: #0f51a1;
  padding: 7px 28px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 12px;
  color: #ffffff;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.head_description {
  font-family: "CircularStd-Medium, sans-serif" !important;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #0a1f44;
}

.cat-desc-head {
  line-height: 1.5;
  height: 65px;
}
.action-col-lg {
  flex: 0 0 70%;
  max-width: 70%;
}

.head-cat-col-lg {
  flex: 0 0 30%;
  max-width: 30%;
}
.cat_desc {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5rem;
}

.cat_desc_btn {
  display: flex;
  flex-wrap: wrap;
}

.right-col {
  float: right;
}

.num_head {
  flex: 0 0 50%;
  max-width: 50%;
}

textarea.wt-resize {
  resize: none;
}

.cat_head .form-group {
  margin-bottom: 0rem;
}

.css-1r4vtzz,
.css-48ayfv {
  padding: 6px 8px 3px 8px;
  background-color: #fff;
  box-shadow: none !important;
  max-width: 100%;
  width: 100% !important;
  border-radius: 4px !important;
  border: 1px solid #8a94a6;
  border-width: 1px !important;
}

.css-1pcexqc-container {
  border: 1px solid #8a94a657;
  border-radius: 4px !important;
}
.css-1jllj6i-control {
  display: none !important;
}

.css-j6qqxb-option {
  font-weight: normal !important;
}

.css-1v99tuv,
.css-18lsk0q-option,
.css-j6qqxb-option {
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 14px;
}

.css-18lsk0q-option:hover {
  background-color: #ffffff00 !important;
}
.css-18lsk0q-option,
.css-j6qqxb-option {
  padding: 9px 15px !important;
}

.css-107lb6w-singleValue {
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 14px;
}

.action-cl-btn {
  margin-right: 12px;
  border-radius: 5px;
  border: solid 1px #ffffff !important;
  background-color: #0f51a1 !important;
  padding: 7.5px 36px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 12px;
  color: #ffffff !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.action-cl-btn-lg {
  margin-right: 12px;
  border-radius: 5px;
  margin-top: 1.75rem;
  border: solid 1px #ffffff !important;
  background-color: #0f51a1 !important;
  padding: 11.5px 36px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 12px;
  color: #ffffff !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  width: 185px;
}

.approve-btn {
  border-radius: 4px;
  border: solid 1px #ffffff;
  background-color: #3ad219;
  padding: 14px;
  width: 20%;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 14px;
  color: #ffffff;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.reject-btn {
  border-radius: 4px;
  border: solid 1px #ffffff;
  background-color: #f64000;
  padding: 14px;
  width: 20%;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 14px;
  color: #ffffff;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.approve-btn:hover,
.reject-btn:hover {
  color: #ffffff;
}
.area-desc {
  width: 684px;
  overflow: hidden;
  display: block;
  word-break: break-word;
  white-space: pre-wrap;
}

.ic-btn {
  border-radius: 3px;
  background-color: transparent;
  font-size: 12px;
  margin-top: 2px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8a94a6;
  margin-right: 6px;
  border: solid 1px #e8e8e8;
}
.ic-btn img {
  margin-right: 9px;
}
.drop-btn {
  margin-left: 12px !important;
  color: #fff !important;
  background-color: #fff !important;
  border-color: #fff !important;
  border: 0px !important;
  padding: 0px !important;
}
.drop-btn:hover {
  margin-left: 12px !important;
  color: #fff !important;
  background-color: #fff !important;
  border-color: #fff !important;
  border: 0px !important;
  padding: 0px !important;
  box-shadow: none !important;
}

.dropdown-dots a {
  padding: 5px 20px;
}
.dropdown-dots-list {
  left: auto !important;
  right: 10px !important;
}

.dropdown-dots-list a {
  padding: 5px 20px;
}

.img-lt {
  margin-right: 15px;
}

.multi-check div:first-child {
  position: unset !important;
}

.export-btn {
  border-radius: 3px !important;
  padding: 7px 10px !important;
  border-color: #e8e8e8 !important;
}

.exp-drp {
  position: absolute;
  margin-top: 0px;
}
.exp-margin {
  margin-left: 105px;
}

.batch-cl-img {
  width: 210px;
  height: 210px;
  border-radius: 8px;
  border: 1px solid #8a94a6;
  margin-top: 1rem;
}

.print-inp {
  margin-bottom: -2rem !important;
}
.rt-mg {
  margin-right: 14px;
}

.rt-mg-2 {
  margin-right: 9px;
}

.date_head {
  flex: 0 0 50%;
  max-width: 50%;
  text-align-last: right;
}

.cn-tp {
  margin-top: 1.65px;
  padding: 6.5px 20px;
}

.drp-tp {
  margin-top: 2.25px;
}

.drp-tp .filter-btn {
  border-radius: 0.25rem;
}

form.user_add .form-control-input {
  font-size: 14px;
  font-family: "CircularStd-Medium, sans-serif" !important;
  border-radius: 4px;
  padding: 0rem 0.25rem;
  color: #4a4a4a;
}

form.user_add .form-control-user[readonly] {
  background: #fff;
  color: #8a94a6;
}

form.user_add .form-control-label {
  font-size: 12px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #8a94a6;
}

.col-sf {
  padding-right: 0.4rem;
  padding-left: 0.4rem;
}

.topright {
  position: absolute;
  top: 0px;
  right: 16px;
}

.cl-btn-lg {
  margin-right: 12px;
  border-radius: 3px;
  padding: 0.375rem 1rem;
  background-color: #fff;
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #8a94a6;
  border: solid 1px #e1e4e8;
  width: 50%;
}

.cl-btn-md {
  margin-left: 3%;
  border-radius: 3px;
  background-color: #fff;
  padding: 0.375rem 0.25rem;
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8a94a6;
  border: solid 1px #e1e4e8;
  width: 45%;
}
.cl-btn-lg img {
  margin-right: 8px;
}

.cl-btn-md img {
  margin-right: 5px;
}
.cl-btn-sm-1 {
  margin-right: 8px;
  border-radius: 3px;
  background-color: #fff;
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8a94a6;
  border: solid 1px #e1e4e8;
}

.cl-btn-sm-2 {
  border-radius: 3px;
  background-color: #fff;
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8a94a6;
  border: solid 1px #e1e4e8;
}
.icon-btn {
  display: initial;
  padding: 0rem 0.5rem 0rem 0rem;
}
.gr-lt-btn {
  float: right;
  margin-left: auto;
}

.cl-comp {
  width: 200px;
  min-width: 200px;
}
.form-head {
  font-family: "CircularStd-Medium, sans-serif" !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 14px;
  letter-spacing: normal;
  color: #0a1f44;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.img-col-staff {
  margin-top: 6%;
}
.file-upload-btn-sf {
  margin-top: 5%;
}

.col-det-sf {
  margin-left: 20px;
}

@media (max-width: 1280px) {
  .col-vdsf {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .cat_head {
    border-radius: 4px;
    border: solid 1px #e1e4e8;
    background-color: #ffffff;
    padding: 12px;
    height: 260px;
    margin-bottom: 1rem;
  }
}
@media (max-width: 1024px) {
  .col-vdsf {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
  .col-sf {
    padding-right: 0.4rem;
    padding-left: 0.4rem;
  }

  .gr-lt-btn {
    float: left;
    margin-right: -2%;
    margin-left: 88%;
    margin-top: 2%;
  }

  #sm-search-filter {
    width: 10rem;
  }

  .add-wd-1024 {
    margin-right: 3px !important;
    padding: 7px 24px;
  }
}

@media (max-width: 768px) {
  .mob-ic {
    margin-top: 2.5px;
  }

  .cat_head {
    border-radius: 4px;
    border: solid 1px #e1e4e8;
    background-color: #ffffff;
    padding: 20px;
    height: 260px;
    margin-bottom: 1rem;
  }

  .col-vdsf {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .head-cat-col-lg {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .action-col-lg {
    flex: 0 0 40%;
    max-width: 40%;
  }
}

@media (max-width: 576px) {
  .action-col-lg {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .head-cat-col-lg {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 480px) {
  .action-col-lg {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .head-cat-col-lg {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .add-btn {
    margin-right: 6px;
    border-radius: 5px;
    border: solid 1px #ffffff;
    background-color: #0f51a1;
    padding: 8px 12px;
    font-family: "CircularStd-Book, sans-serif" !important;
    font-size: 12px;
    color: #ffffff;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}

@media (max-width: 411px) {
  .col-vdsf {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.mailto {
  color: #9b9eac;
}
.isPermissionDiv label {
  position: absolute;
  top: 3px;
  left: 30px;
}
.isPermissionDiv input {
  height: 15px;
  width: 15px;
}

.switch .slide-yes-no-wrap {
  background: #efefef;
  padding: 2px 24px;
  border-radius: 50px;
}

.switch .slide-yes-no-wrap .yes-field {
  margin-right: 10px;
  cursor: pointer;
}
.switch .slide-yes-no-wrap .no-field {
  margin-left: 15px;
  cursor: pointer;
}
.switch .slide-yes-no-wrap .separator-field {
  top: -1px;
  position: absolute;
}
.switch .slide-yes-no-wrap .active {
  color: #0f51a1;
  cursor: default;
}

.switch .form-control-label {
  padding-right: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #4a4a4a;
}

.request-for-modification-btn {
  width: 27%;
}

.footer-pg .dropdown-staff {
  bottom: 70px !important;
}

.footer-pg .dropdown-staff::before {
  top: auto;
  bottom: -5px !important;
  transform: rotate(180deg) !important;
}
