.modal-full-body-counterfeit {
  padding: 0rem !important;
}

.modal-full-body-counterfeit .close {
  padding-right: 0.35rem !important;
  padding-left: 0.35rem !important;
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  background-color: white;
  opacity: 1;
  position: absolute;
  left: 92%;
  top: 2%;
  color: #8a94a6bd;
  font-size: 27px;
  font-weight: 200;
  border-radius: 2px;
}

.modal-full-body-counterfeit .close:focus {
  outline: 0 !important;
}
.image-modal-view-counterfeit {
  width: 100%;
  height: 548px;
}

.left-ar-counterfeit {
  top: 45%;
  position: absolute;
  left: 2%;
}
.right-ar-counterfeit {
  top: 45%;
  left: 92%;
  position: absolute;
}
.footer-img {
  width: 70px;
  height: 70px;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 6px;
}

.gallery-trash {
  position: absolute;
  margin-left: -5.5%;
}
.center-item {
  justify-content: center !important;
}
.product-image-gallery .modal-content {
  border-radius: 0px !important;
}
