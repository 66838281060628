.contact-button {
  background-color: #0f51a1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 30px;
  width: 94px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
}

.container-user-details {
  width: 100%;
  grid-template-columns: auto auto auto auto;
  display: grid;
  padding-top: 35px;
}

.status-field {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.button-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
}
@media (max-width: 1220px) {
  .container-user-details {
    width: 100%;
    grid-template-columns: auto auto auto auto;
    display: grid;
    padding-top: 35px;
  }
}

@media (max-width: 998px) {
  .container-user-details {
    width: 100%;
    grid-template-columns: auto auto auto;
    display: grid;
    padding-top: 35px;
  }
  .button-container {
    display: grid;
    grid-template-columns: auto auto auto;
    row-gap: 6px;
  }
}

@media (max-width: 875px) {
  .container-user-details {
    width: 100%;
    grid-template-columns: auto auto;
    display: grid;
    padding-top: 35px;
  }
  .status-field {
    display: flex;
    flex-direction: column;
    margin: 0px;
  }
  .button-container {
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 6px;
  }
}

.btn.reopen-main-container:hover > span.child-repoen-span svg {
  stroke: #fff; /* Your desired hover stroke color */
}

.text-area .form-group .ql-editor {
  min-height: 240px;
}
