.pre-desc {
  line-height: 24px;
  /* padding-top: 24px; */
  width: 484px;
  overflow: hidden;
  word-break: break-word;
  white-space: pre-line;
}

.pname-desc {
  width: 180px;
  overflow: hidden;
  word-break: break-word;
  white-space: pre-line;
}
.bold-desc {
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #0a1f44 !important;
}

.page-desc {
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  margin-top: 3px;
  color: #8a94a6 !important;
}

.page-desc-left {
  font-size: 14px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #8a94a6 !important;
}
.bold-black {
  color: #0a1f44 !important;
}

.list-img {
  width: 56px;
  height: 56px;
}

.top-open.show {
  display: block;
  top: auto !important;
}

.action-col-lg {
  flex: 0 0 70%;
  max-width: 70%;
}

.head-cat-col-lg {
  flex: 0 0 30%;
  max-width: 30%;
}
.add-btn {
  margin-right: 12px;
  border-radius: 5px;
  border: solid 1px #ffffff;
  background-color: #0f51a1;
  padding: 7px 28px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 12px;
  color: #ffffff;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.product-img-width-th {
  width: 95px;
}
.product-image-gallery .modal-content {
  height: 550px;
  width: 100%;
}
.product-image-gallery .modal-body {
  background-color: #e6dbd4;
}
.gallery-footer {
  justify-content: center;
}
@media (max-width: 768px) {
  .action-col-lg {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .head-cat-col-lg {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 576px) {
  .action-col-lg {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .head-cat-col-lg {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 480px) {
  .side-col {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .action-col-lg {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .head-cat-col-lg {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .add-btn {
    margin-right: 6px;
    border-radius: 5px;
    border: solid 1px #ffffff;
    background-color: #0f51a1;
    padding: 8px 12px;
    font-family: "CircularStd-Book, sans-serif" !important;
    font-size: 12px;
    color: #ffffff;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}

@media (max-width: 320px) {
  .side-col {
    flex: 0 0 65%;
    max-width: 65%;
  }

  .add-btn {
    margin-right: 6px;
    border-radius: 5px;
    border: solid 1px #ffffff;
    background-color: #0f51a1;
    padding: 8px 11px;
    font-family: "CircularStd-Book, sans-serif" !important;
    font-size: 12px;
    color: #ffffff;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}

.product_add {
  margin-left: 7.5px;
  margin-right: 7.5px;
}

.footer-form {
  text-align: center;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}

.mob-hide {
  display: block;
}

.mob-show {
  display: none;
}

.submit-btn {
  border-radius: 4px;
  margin-right: 6px;
  border: solid 1px #ffffff;
  background-color: #0f51a1;
  padding: 12px;
  width: 20%;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 14px;
  color: #ffffff;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.cancel-btn {
  margin-right: 6px;
  border-radius: 4px;
  border: solid 1px #8894a8;
  background-color: #ffffff;
  padding: 11px;
  width: 20%;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 14px;
  color: #8894a8;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

form.product_add .form-control-product {
  font-size: 14px;
  font-family: "CircularStd-Medium, sans-serif" !important;
  border-radius: 4px;
  padding: 0rem 0rem;
  color: #4a4a4a;
}

form.product_add .form-control-input {
  font-size: 14px;
  font-family: "CircularStd-Medium, sans-serif" !important;
  border-radius: 4px;
  height: calc(1.5em + 0.75rem + 5px);
  padding: 0rem 0.25rem;
  color: #4a4a4a;
}

form.product_add .form-control-textarea {
  font-size: 14px;
  font-family: "CircularStd-Medium, sans-serif" !important;
  border-radius: 4px;
  padding: 0.75em 0.25rem;
  color: #4a4a4a;
}

form.product_add .form-control-label {
  font-size: 12px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #8a94a6;
}

.img-col-product {
  margin-top: 10%;
}

.product-img {
  width: 150px;
  height: 150px;
  border-radius: 8px;
}

.file-upload-btn-product {
  margin-left: 0px;
  margin-top: 5%;
}

.file-label {
  margin-right: 10px;
  border-radius: 5px;
  border: solid 1px #0f51a1;
  background-color: #ffffff;
  padding: 6px 15px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 12px;
  color: #0f51a1;
  cursor: pointer;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 0;
}

input[type="file"] {
  display: none;
}

@media (max-width: 768px) {
  .cancel-btn,
  .submit-btn {
    width: 100%;
    margin-bottom: 10px;
  }

  .mob-hide {
    display: none;
  }

  .mob-show {
    display: block;
  }

  textarea.wt-resize {
    resize: none;
  }

  .img-col-product {
    margin-left: 0px !important;
    margin-top: 0% !important;
    float: left;
    margin-bottom: 2.5%;
  }

  .user-product {
    width: 150px;
    height: 150px;
    border-radius: 4px;
  }

  .file-upload-btn-product {
    margin-left: 0px;
    margin-top: 10%;
    margin-right: 12px;
  }

  .file-label {
    margin-left: 12px;
    margin-right: 6px;
    border-radius: 5px;
    border: solid 1px #0f51a1;
    background-color: #ffffff;
    padding: 6px 15px;
    font-family: "CircularStd-Book, sans-serif" !important;
    font-size: 12px;
    color: #0f51a1;
    cursor: pointer;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 0;
  }

  .file-upload-btn a {
    margin-left: 12px;
  }
}
form.product_add.proAdd p {
  font-weight: bold;
  color: #041e46;
}

form.product_add.proAdd .col-md-12 {
  padding: 0 25px;
}

.proAdd label {
  font-weight: 500 !important;
  color: #8a94a6 !important;
  font-size: 12px !important;
  line-height: 15px;
  margin-bottom: 3px;
  font-family: "CircularStd-Book, sans-serif" !important;
}

.faqbottomclass {
  margin-bottom: 35px !important;
}
