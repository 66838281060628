.add-btn {
  margin-right: 12px;
  border-radius: 5px;
  border: solid 1px #ffffff;
  background-color: #0f51a1;
  padding: 7px 28px;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-size: 12px;
  color: #ffffff;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.page-desc {
  font-size: 12px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  margin-top: 3px;
  color: #8a94a6 !important;
}

.page-desc-left {
  font-size: 14px;
  font-weight: normal;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #8a94a6 !important;
}

.top-open.show {
  display: block;
  top: auto !important;
}

@media (max-width: 480px) {
  .side-col {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .add-btn {
    margin-right: 6px;
    border-radius: 5px;
    border: solid 1px #ffffff;
    background-color: #0f51a1;
    padding: 8px 14px;
    font-family: "CircularStd-Book, sans-serif" !important;
    font-size: 12px;
    color: #ffffff;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}

@media (max-width: 320px) {
  .side-col {
    flex: 0 0 65%;
    max-width: 65%;
  }

  .add-btn {
    margin-right: 6px;
    border-radius: 5px;
    border: solid 1px #ffffff;
    background-color: #0f51a1;
    padding: 8px 11px;
    font-family: "CircularStd-Book, sans-serif" !important;
    font-size: 12px;
    color: #ffffff;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}
