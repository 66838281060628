.textareaPrivacyTerms .card-header {
  padding: 0 !important;
  border: none;
}

.textareaPrivacyTerms .card-header h6.font-book {
  font-size: 14px;
  color: #041e46;
}

.textareaPrivacyTerms .card-main {
  padding: 30px;
}

.textareaPrivacyTerms .card-main .card-body.card-table {
  padding: 20px 0 0 !important;
}

.textareaPrivacyTerms .card-main .card-body.card-table .ql-editor {
  min-height: 240px;
}

.textareaPrivacyTerms .card-main .card-body.card-table .footer-form {
  text-align: center;
  padding: 10px 0 0;
}
