.invitestaffmodal .modal-dialog {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invitestaffmodal .modal-content {
  background: #ffffff !important;
  box-shadow: 0px 8px 28px -8px rgb(16 24 40 / 6%),
    0px 0px 7px -2px rgb(16 24 40 / 8%) !important;
  border-radius: 12px !important;
  border: 0;
}

.invitestaffmodal .modal-dialog .modal-body {
  padding: 27px 32px 32px 32px;
}

.invitestaffmodal .modal-dialog .modal-body .form-control-label {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #6f8381;
}

.invitestaffmodal .modal-dialog .modal-body .form-control {
  height: 40px;
  font-size: 14px;
  padding-left: 11px;
}

.invitestaffmodal
  .modal-dialog
  .modal-body
  .col-md-6:nth-child(1)
  .form-group.mb-20px,
.invitestaffmodal
  .modal-dialog
  .modal-body
  .col-md-6:nth-child(2)
  .form-group.mb-20px {
  margin-bottom: 11px;
}

.invitestaffmodal .modal-dialog .modal-body select.form-control.form-select {
  -webkit-appearance: none;
  /* background-image: url("../img/arrowSelect.svg"); */
  background-repeat: no-repeat;
  background-position: 94%;
  background-position-y: 51%;
}

.invitestaffmodal .modal-dialog {
  max-width: 494px;
}

.invitestaffmodal button {
  max-height: 40px;
  outline: 0;
  border: 0;
}

.invitestaffmodal .customCancelBtn {
  height: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  font-family: "CircularStd-Medium, sans-serif" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #8894a8;
  background-color: transparent;
}

.invitestaffmodal .customSubmitBtn {
  height: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "CircularStd-Book, sans-serif" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;
}
